import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue } from "firebase/database";
import { database } from '../firebase';
import './CreatorPage.css';
import Adventureicon from '../assets/genres/adventure-dark.svg';
import Battleroyalicon from '../assets/genres/battleroyale-dark.svg';
import Fightingicon from '../assets/genres/fighting-dark.svg';
import FPSicon from '../assets/genres/fps-dark.svg';
import Minigamesicon from '../assets/genres/minigames-dark.svg';
import Racingicon from '../assets/genres/racing-dark.svg';
import RPGicon from '../assets/genres/rpg-dark.svg';
import Strategyicon from '../assets/genres/strategy-dark.svg';
import Survivalicon from '../assets/genres/survival-dark.svg';
import locationDropIcon from '../assets/images/location-drop-icon.svg';
import ReactFlagsSelect from 'react-flags-select'; // Import ReactFlagsSelect
import InstaIcon from '../assets/socials/insta-dark.svg';
import TiktokIcon from '../assets/socials/tiktok-dark.svg';
import XIcon from '../assets/socials/X-dark.svg';
import YoutubeSocialIcon from '../assets/socials/youtube-dark.svg';
import TwitchIcon from '../assets/socials/twitch-dark.svg'; // Import Twitch icon
import FacebookIcon from '../assets/socials/facebook-dark.svg';
import Footer from '../components/Footer'; // Adjust the path as necessary
import axios from 'axios'; // Import Axios

const genreIcons = {
  Adventure: Adventureicon,
  'Battle Royale': Battleroyalicon,
  Fighting: Fightingicon,
  FPS: FPSicon,
  'Minigames': Minigamesicon,
  Racing: Racingicon,
  RPG: RPGicon,
  Strategy: Strategyicon,
  Survival: Survivalicon,
};

const languageFlags = {
  Afrikaans: 'ZA',
  Albanian: 'AL',
  Arabic: 'AE',
  Armenian: 'AM',
  Basque: 'ES',
  Bengali: 'BD',
  Bulgarian: 'BG',
  Catalan: 'ES',
  Chinese: 'CN',
  Croatian: 'HR',
  Czech: 'CZ',
  Danish: 'DK',
  Dutch: 'NL',
  English: 'US',
  Estonian: 'EE',
  Finnish: 'FI',
  French: 'FR',
  Galician: 'ES',
  Georgian: 'GE',
  German: 'DE',
  Greek: 'GR',
  Hebrew: 'IL',
  Hindi: 'IN',
  Hungarian: 'HU',
  Icelandic: 'IS',
  Indonesian: 'ID',
  Irish: 'IE',
  Italian: 'IT',
  Japanese: 'JP',
  Korean: 'KR',
  Latvian: 'LV',
  Lithuanian: 'LT',
  Macedonian: 'MK',
  Malay: 'MY',
  Maltese: 'MT',
  Norwegian: 'NO',
  Persian: 'IR',
  Polish: 'PL',
  Portuguese: 'PT',
  Romanian: 'RO',
  Russian: 'RU',
  Serbian: 'RS',
  Slovak: 'SK',
  Slovenian: 'SI',
  Spanish: 'ES',
  Swahili: 'KE',
  Swedish: 'SE',
  Thai: 'TH',
  Turkish: 'TR',
  Ukrainian: 'UA',
  Vietnamese: 'VN',
  Welsh: 'GB',
  Yiddish: 'IL',
};

const regionNames = {
  NA: "North America",
  EU: "Europe",
  EAS: "East Asia",
  SA: "South America",
  AU: "Australia and Pacific Islands",
  CAS: "Central Asia",
  ME: "Middle East",
  NAF: "North Africa",
  RU: "Russia",
  SAF: "Sub-Saharan Africa",
  SAS: "South Asia",
  SEA: "South-East Asia",
  CA: "Central America",
};

const getHandleFromUrl = (url, platform) => {
  const urlObj = new URL(url);
  switch (platform) {
    case 'instagram':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'youtube':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'x':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'tiktok':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'twitch':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'facebook':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    default:
      return '';
  }
};

const API_KEY = 'd56fb269b2d345f3a6261a0dc8a34a28'; // Your Embedly API key

const fetchEmbedData = async (url) => {
  if (!url) {
    console.error('No URL provided for embed data fetching');
    return null; // Return null if the URL is empty
  }

  try {
    const response = await axios.get(`https://api.embedly.com/1/oembed?key=${API_KEY}&url=${encodeURIComponent(url)}&native=true`);
    return response.data;
  } catch (error) {
    console.error('Error fetching embed data:', error);
    return null;
  }
};

const PublicProfile = () => {
  const { username } = useParams(); // Get username from URL parameters
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [embedData, setEmbedData] = useState([]); // State for embed data
  const [isCollapsed, setIsCollapsed] = useState(false);
  const scrollableContentRef = useRef(null); // Create a ref for the scrollable-content div

  useEffect(() => {
    const usersRef = ref(database, 'users'); // Reference to the users node
    const unsubscribe = onValue(usersRef, (snapshot) => {
      const users = snapshot.val();
      let userId = null;

      // Find the user ID based on the username
      for (const id in users) {
        if (users[id].username === username) {
          userId = id;
          break;
        }
      }

      if (userId) {
        // If userId is found, fetch the user data
        const userRef = ref(database, `users/${userId}`);
        onValue(userRef, async (userSnapshot) => {
          const user = userSnapshot.val();
          if (user) {
            console.log('User data found:', user); // Log the user data
            setUserData(user);
            // Fetch embed data for featured content
            if (user.featureContent) {
              const links = user.featureContent.split(',').map(link => link.trim()).filter(link => link); // Trim and filter out empty links
              const embedPromises = links.map(link => {
                console.log('Fetching embed data for URL:', link); // Log the URL
                return fetchEmbedData(link);
              });
              const embeds = await Promise.all(embedPromises);
              setEmbedData(embeds.filter(embed => embed)); // Filter out null responses
            }
          } else {
            console.error('No data found for user ID:', userId);
          }
          setLoading(false); // Set loading to false after attempting to fetch user data
        });
      } else {
        console.error('No user found with username:', username);
        setLoading(false);
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [username]);

  useEffect(() => {
    if (!loading && userData) {
      const handleScroll = () => {
        const scrollTop = scrollableContentRef.current.scrollTop;
        console.log('Scroll event triggered'); // Log to check if the event is firing
        console.log('Current scroll position:', scrollTop);

        if (scrollTop > 1) {
          setIsCollapsed(true);
          console.log('Banner collapsed:', true);
        } else {
          setIsCollapsed(false);
          console.log('Banner collapsed:', false);
        }
      };

      const scrollableDiv = scrollableContentRef.current;
      if (scrollableDiv) {
        scrollableDiv.addEventListener('scroll', handleScroll);
        console.log('Scroll event listener attached');
      }

      // Trigger the scroll event manually on load to check initial position
      handleScroll();

      return () => {
        if (scrollableDiv) {
          scrollableDiv.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [loading, userData]); // Run this effect when loading or userData changes

  if (loading) {
    console.log('Loading user data...'); // Log loading state
    return <div className="loading-container"><h2 style={{ textAlign: 'center' }}>Loading shareable profile...</h2><div className="loading-spinner"></div></div>; // Show loading state while fetching data
  }

  if (!userData) {
    console.log('User data not found for username:', username); // Log if user data is not found
    return <p>User data not found.</p>; // Handle case where userData is not available
  }

  return (
    <div className={`creator-page header-app-route public-profile`}>
      <div className={`profile-banner ${isCollapsed ? 'collapsed' : ''}`}>
        <img src={userData.profileBanner} alt="Profile Banner" className="banner-image" />
        <div className="overlay"></div>
        <div className={`profile-info ${isCollapsed ? 'collapsed' : ''}`}>
          <img src={userData.profilePic} alt="Profile" className="profile-pic" />
          <h2 className="profile-displayname">{userData.name}</h2>
          <div className="profile-location">
            <img src={locationDropIcon} alt="Location Icon" className="location-drop-icon" />
            <p>{regionNames[userData.locationAnswer] || userData.locationAnswer}</p>
          </div>
        </div>
      </div>
      <div className="scrollable-content" ref={scrollableContentRef}>
        <div className="profile-details-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="question-container bio">
            <p>{userData.creatorBio}</p>
          </div>
          <div className="profile-details">
            <div className="columns">
              <div className="column left-column">
                <div className="question-container social-accounts">
                  <div className="section-title">
                    <h3>Socials</h3>
                  </div>
                  <div className="social-accounts-list">
                    {userData.contentPlatforms.split(',').map((contentPlatform, index) => {
                      const trimmedPlatform = contentPlatform.trim();
                      const socialUrl = userData.socialUrls[trimmedPlatform];

                      return (
                        socialUrl && ( // Only render if socialUrl is populated
                          <div key={index} className="social-account-item">
                            <div className="social-links">
                              <div className="social-link-item">
                                {trimmedPlatform === 'instagram' && (
                                  <>
                                    <img src={InstaIcon} alt="Instagram" className="social-icon" />
                                    <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                      @{getHandleFromUrl(socialUrl, 'instagram')}
                                    </a>
                                  </>
                                )}
                                {trimmedPlatform === 'tiktok' && (
                                  <>
                                    <img src={TiktokIcon} alt="TikTok" className="social-icon" />
                                    <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                      @{getHandleFromUrl(socialUrl, 'tiktok').replace('@', '')}
                                    </a>
                                  </>
                                )}
                                {trimmedPlatform === 'x' && (
                                  <>
                                    <img src={XIcon} alt="X" className="social-icon" />
                                    <a className="handle-link" href={socialUrl} target="_blank" rel="noopener noreferrer">
                                      @{getHandleFromUrl(socialUrl, 'x')}
                                    </a>
                                  </>
                                )}
                                {trimmedPlatform === 'youtube' && (
                                  <>
                                    <img src={YoutubeSocialIcon} alt="YouTube" className="social-icon youtube-icon" />
                                    <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                      @{getHandleFromUrl(socialUrl, 'youtube').replace('@', '')}
                                    </a>
                                  </>
                                )}
                                {trimmedPlatform === 'twitch' && (
                                  <>
                                    <img src={TwitchIcon} alt="Twitch" className="social-icon" />
                                    <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                      @{getHandleFromUrl(socialUrl, 'twitch')}
                                    </a>
                                  </>
                                )}
                                {trimmedPlatform === 'facebook' && (
                                  <>
                                    <img src={FacebookIcon} alt="Facebook" className="social-icon" />
                                    <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                      @{getHandleFromUrl(socialUrl, 'facebook')}
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="column center-column">
                <div className="question-container featured-content">
                  <div className="section-title featured-content">
                    <h3>Featured Content</h3>
                  </div>
                  {embedData.map((embed, index) => (
                    <div key={index} className="embedded-content">
                      <div dangerouslySetInnerHTML={{ __html: embed.html }} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="column right-column">
                <div className="question-container genres">
                  <div className="section-title">
                    <h3>Genres</h3>
                  </div>
                  <div className="genre-icons">
                    {userData.genrePreference.split(',').map((genre, index) => (
                      <img 
                        key={index} 
                        src={genreIcons[genre.trim()]} 
                        alt={genre.trim()} 
                        className="genre-icon" 
                      />
                    ))}
                  </div>
                </div>
                <div className="question-container languages">
                  <div className="section-title">
                    <h3>Languages</h3>
                  </div>
                  <div className="languages-list">
                    {userData.language.split(',').map((language, index) => {
                      const trimmedLanguage = language.trim();
                      const countryCode = languageFlags[trimmedLanguage]; // Get the country code

                      return (
                        <div key={index} className="language-item">
                          <ReactFlagsSelect style={{ backgroundColor: 'transparent' }}
                            selected={countryCode}
                            countries={Object.values(languageFlags)}
                            customLabels={{
                              [countryCode]: trimmedLanguage // Use the language name as the label
                            }}
                            disabled
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PublicProfile;