import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo_title.png'; // Import the logo image
import './CreatorTypeform.css';
import { ref, update, get } from "firebase/database"; // Import Firebase functions
import { database } from '../firebase'; // Import your Firebase database
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner component
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
import continueIcon from '../assets/continue-icon.svg'; // Import the SVG icon
import backIcon from '../assets/chevron-left.svg';
import Adventureicon from '../assets/genres/adventure-dark.svg';
import Battleroyalicon from '../assets/genres/battleroyale-dark.svg';
import Fightingicon from '../assets/genres/fighting-dark.svg';
import FPSicon from '../assets/genres/fps-dark.svg';
import Minigamesicon from '../assets/genres/minigames-dark.svg';
import Racingicon from '../assets/genres/racing-dark.svg';
import RPGicon from '../assets/genres/rpg-dark.svg';
import Strategyicon from '../assets/genres/strategy-dark.svg';
import Survivalicon from '../assets/genres/survival-dark.svg';
import AdventureSelected from '../assets/genres/Adventure.svg';
import BattleroyalSelected from '../assets/genres/Battle-Royale.svg';
import FightingSelected from '../assets/genres/Fighting.svg';
import FPSSelected from '../assets/genres/FPS.svg';
import MinigamesSelected from '../assets/genres/Minigames.svg';
import RacingSelected from '../assets/genres/Racing-icon.svg';
import RPGSelected from '../assets/genres/RPG.svg';
import StrategySelected from '../assets/genres/Strategy.svg';
import SurvivalSelected from '../assets/genres/Survival.svg';
import WorldMap from '../assets/regions/world-dark.svg'; // Default world map
import WorldNA from '../assets/regions/world-NA.svg'; // North America map
import WorldEU from '../assets/regions/world-EU.svg'; // Europe map
import WorldEAS from '../assets/regions/world-EAS.svg'; // East Asia map
import WorldSA from '../assets/regions/world-SA.svg'; // South America map
import WorldAU from '../assets/regions/world-AU.svg'; // Australia and PI map
import WorldCAS from '../assets/regions/world-CAS.svg'; // Central Asia map
import WorldME from '../assets/regions/world-ME.svg'; // Middle East map
import WorldNAF from '../assets/regions/world-NAF.svg'; // North Africa map
import WorldRU from '../assets/regions/world-RU.svg'; // Russia map
import WorldSAF from '../assets/regions/world-SAF.svg'; // Sub-Saharan Africa map
import WorldSAS from '../assets/regions/world-SAS.svg'; // South Asia map
import WorldSEA from '../assets/regions/world-SEA.svg'; // South-East Asia map
import WorldCA from '../assets/regions/world-CA.svg'; // Central America map
// Import other region maps as needed
import { auth } from '../firebase'; // Import Firebase auth
import { signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import './CreatorTypeform.css'; // Ensure you have a CSS file for styling
import ReactFlagsSelect from 'react-flags-select';
import downloadIcon from '../assets/images/Download-icon.svg'; // Path to your download icon
import checkIcon from '../assets/images/Check-icon.svg'; // Path to your check icon
import addLinkIcon from '../assets/images/add-link.svg'; // Import the SVG icon
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase Storage functions

// Define a list of major languages with their corresponding country codes
const languages = [
  { name: 'Afrikaans', code: 'ZA' },
  { name: 'Albanian', code: 'AL' },
  { name: 'Arabic', code: 'AE' },
  { name: 'Armenian', code: 'AM' },
  { name: 'Basque', code: 'ES' },
  { name: 'Bengali', code: 'BD' },
  { name: 'Bulgarian', code: 'BG' },
  { name: 'Catalan', code: 'ES' },
  { name: 'Chinese', code: 'CN' },
  { name: 'Croatian', code: 'HR' },
  { name: 'Czech', code: 'CZ' },
  { name: 'Danish', code: 'DK' },
  { name: 'Dutch', code: 'NL' },
  { name: 'English', code: 'US' },
  { name: 'Estonian', code: 'EE' },
  { name: 'Filipino', code: 'PH' },
  { name: 'Finnish', code: 'FI' },
  { name: 'French', code: 'FR' },
  { name: 'Galician', code: 'ES' },
  { name: 'Georgian', code: 'GE' },
  { name: 'German', code: 'DE' },
  { name: 'Greek', code: 'GR' },
  { name: 'Hebrew', code: 'IL' },
  { name: 'Hindi', code: 'IN' },
  { name: 'Hungarian', code: 'HU' },
  { name: 'Icelandic', code: 'IS' },
  { name: 'Indonesian', code: 'ID' },
  { name: 'Irish', code: 'IE' },
  { name: 'Italian', code: 'IT' },
  { name: 'Japanese', code: 'JP' },
  { name: 'Korean', code: 'KR' },
  { name: 'Latvian', code: 'LV' },
  { name: 'Lithuanian', code: 'LT' },
  { name: 'Macedonian', code: 'MK' },
  { name: 'Malay', code: 'MY' },
  { name: 'Maltese', code: 'MT' },
  { name: 'Norwegian', code: 'NO' },
  { name: 'Persian', code: 'IR' },
  { name: 'Polish', code: 'PL' },
  { name: 'Portuguese', code: 'PT' },
  { name: 'Romanian', code: 'RO' },
  { name: 'Russian', code: 'RU' },
  { name: 'Serbian', code: 'RS' },
  { name: 'Slovak', code: 'SK' },
  { name: 'Slovenian', code: 'SI' },
  { name: 'Spanish', code: 'ES' },
  { name: 'Swahili', code: 'KE' },
  { name: 'Swedish', code: 'SE' },
  { name: 'Thai', code: 'TH' },
  { name: 'Turkish', code: 'TR' },
  { name: 'Ukrainian', code: 'UA' },
  { name: 'Vietnamese', code: 'VN' },
  { name: 'Welsh', code: 'GB' },
  { name: 'Yiddish', code: 'IL' },
];


const genresList = [
  { name: 'Adventure', icon: Adventureicon, selectedIcon: AdventureSelected },
  { name: 'Battle Royale', icon: Battleroyalicon, selectedIcon: BattleroyalSelected },
  { name: 'Fighting', icon: Fightingicon, selectedIcon: FightingSelected },
  { name: 'FPS', icon: FPSicon, selectedIcon: FPSSelected },
  { name: 'Minigames', icon: Minigamesicon, selectedIcon: MinigamesSelected },
  { name: 'Racing', icon: Racingicon, selectedIcon: RacingSelected },
  { name: 'RPG', icon: RPGicon, selectedIcon: RPGSelected },
  { name: 'Strategy', icon: Strategyicon, selectedIcon: StrategySelected },
  { name: 'Survival', icon: Survivalicon, selectedIcon: SurvivalSelected },
];

const socialMediaList = [
  { name: 'instagram', icon: 'insta-dark.svg', selectedIcon: 'insta-light.svg' },
  { name: 'tiktok', icon: 'tiktok-dark.svg', selectedIcon: 'tiktok-light.svg' },
  { name: 'youtube', icon: 'youtube-dark.svg', selectedIcon: 'youtube-light.svg' },
  { name: 'x', icon: 'X-dark.svg', selectedIcon: 'X-light.svg' },
  { name: 'facebook', icon: 'facebook-dark.svg', selectedIcon: 'facebook-light.svg' },
  { name: "twitch", icon: "twitch-dark.svg", selectedIcon: "twitch-light.svg" },
];


const CreatorTypeform = () => {
  const location = useLocation();
  const { userId: userIdFromState } = location.state || { userId: null };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [username, setUsername] = useState('');
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedSocials, setSelectedSocials] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(''); // State for selected region
  const [map, setMap] = useState(WorldMap); // State for the current map
  const [languageInput, setLanguageInput] = useState('');
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const [creatorBio, setCreatorBio] = useState('');
  const [featureContent, setFeatureContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUsernameUnique, setIsUsernameUnique] = useState(true);
  const navigate = useNavigate();

  const logoRef = useRef(null);

  const [clickedCoordinates, setClickedCoordinates] = useState({ x: 0, y: 0 });
  const [profilePicUploaded, setProfilePicUploaded] = useState(false);
  const [profileBannerUploaded, setProfileBannerUploaded] = useState(false);
  const [linkCount, setLinkCount] = useState(1); // State to manage the number of link inputs
  const [socialUrls, setSocialUrls] = useState({}); // State to hold social URLs

  useEffect(() => {
    if (window.self !== window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);

  const handleRegionChange = (event) => {
    const region = event.target.value;
    setSelectedRegion(region);

    // Update the map based on the selected region
    switch (region) {
      case 'NA':
        setMap(WorldNA);
        break;
      case 'EU':
        setMap(WorldEU); // Add the corresponding SVG for Europe
        break;
      case 'EAS':
        setMap(WorldEAS); // Add the corresponding SVG for East Asia
        break;
      case 'SA':
        setMap(WorldSA); // Add the corresponding SVG for South America
        break;
      case 'AU':
        setMap(WorldAU); // Add the corresponding SVG for Australia and PI
        break;
      case 'CAS':
        setMap(WorldCAS); // Add the corresponding SVG for Central Asia
        break;
      case 'ME':
        setMap(WorldME); // Add the corresponding SVG for Middle East
        break;
      case 'NAF':
        setMap(WorldNAF); // Add the corresponding SVG for North Africa
        break;
      case 'RU':
        setMap(WorldRU); // Add the corresponding SVG for Russia
        break;
      case 'SAF':
        setMap(WorldSAF); // Add the corresponding SVG for Sub-Saharan Africa
        break;
      case 'SAS':
        setMap(WorldSAS); // Add the corresponding SVG for South Asia
        break;
      case 'SEA':
        setMap(WorldSEA); // Add the corresponding SVG for South-East Asia
        break;
      case 'CA':
        setMap(WorldCA); // Add the corresponding SVG for Central America
        break;
      default:
        setMap(WorldMap);
    }
  };

  const handleLanguageChange = (e) => {
    const value = e.target.value;
    setLanguageInput(value);

    // Split the input by commas and filter based on the last entry
    const lastEntry = value.split(',').pop().trim();
    if (lastEntry) {
      setFilteredLanguages(
        languages.filter(lang => lang.name.toLowerCase().includes(lastEntry.toLowerCase()))
      );
    } else {
      setFilteredLanguages([]);
    }

    // Check if the last entry was deleted
    if (value.endsWith(', ')) {
      const newLanguages = selectedLanguages.slice(0, -1); // Remove the last selected language
      setSelectedLanguages(newLanguages);
    }
  };

  const handleLanguageSelect = (lang) => {
    if (selectedLanguages.length < 5) {
      const newLanguages = [...selectedLanguages, lang.name];
      setSelectedLanguages(newLanguages);
      setLanguageInput(newLanguages.join(', ') + ', '); // Add a comma for the next input
      setFilteredLanguages([]); // Clear suggestions after selection
    }
  };

  useEffect(() => {
    const checkUsernameUnique = async () => {
      if (currentQuestion === 0 && username) { // Only check if it's the username question
        const snapshot = await get(ref(database, 'users'));
        const users = snapshot.val();
        const existingNames = Object.values(users)
          .map(user => user.username ? user.username.toLowerCase() : null) // Check if username exists
          .filter(username => username !== null); // Filter out null values
        setIsUsernameUnique(!existingNames.includes(username.toLowerCase())); // Convert input username to lowercase
      } else {
        setIsUsernameUnique(true);
      }
    };

    const handler = setTimeout(() => {
      checkUsernameUnique();
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [username, currentQuestion]); // Add currentQuestion to the dependency array

  useEffect(() => {
    // Scroll to the logo when the current question changes
    if (currentQuestion >= 0 && logoRef.current) {
      const logoPosition = logoRef.current.getBoundingClientRect().top + window.scrollY;
      const offset = 50; // Adjust this value to set how high above the logo you want to scroll
      window.scrollTo({
        top: logoPosition - offset,
        behavior: 'smooth'
      });
    }
  }, [currentQuestion]);

  useEffect(() => {
  }, [currentQuestion]);

  if (!userIdFromState) {
    console.error('User ID is undefined');
    return <div>Error: User ID is not available.</div>;
  }

  const questions = [
    { question: "What should we call you?", answer: username, setAnswer: setUsername },
    { question: "Your favorite genres", answer: selectedGenres, setAnswer: setSelectedGenres },
    { question: "Where do you create content?", answer: selectedSocials, setAnswer: setSelectedSocials },
    { question: "Where are you located?", answer: selectedRegion, setAnswer: setSelectedRegion },
    { question: "What languages do you speak?", answer: selectedLanguages, setAnswer: setSelectedLanguages },
    { question: "Set up your shareable profile!", answer: '', setAnswer: () => {}, titleClass: 'shareable-profile-title' }, // Add a titleClass property
  ];

  const validateData = () => {
    if (currentQuestion === 0 && !username) {
      alert("Please fill in your username.");
      return false;
    }
    if (currentQuestion === 0 && !isUsernameUnique) {
      alert("Username must be unique.");
      return false;
    }
    if (currentQuestion === 1 && !selectedGenres.length) {
      alert("Please select at least one genre.");
      return false;
    }
    if (currentQuestion === 2 && selectedSocials.length === 0) {
      alert("Please select at least one social media platform.");
      return false;
    }
    if (currentQuestion === 3 && !selectedRegion) {
      alert("Please select a region.");
      return false;
    }
    if (currentQuestion === 4 && !selectedLanguages.length) {
      alert("Please select at least one language.");
      return false;
    }
    if (currentQuestion === 6) {
      const urls = featureContent.split(',').map(url => url.trim());
      if (urls.length > 5) {
        alert("Please add up to 5 links only.");
        return false;
      }
      for (let url of urls) {
        if (!isValidURL(url)) {
          alert("Please enter valid URLs.");
          return false;
        }
      }
    }
    return true; // All validations passed
  };

  const isValidURL = (string) => {
    const res = string.match(/(https?:\/\/[^\s]+)/g);
    return (res !== null);
  };

  const handleNext = async (e) => {
    e.preventDefault();

    // Check for spaces in the username name
    if (currentQuestion === 0 && username.trim().includes(' ')) {
      alert("Username name cannot contain spaces.");
      return;
    }

    // Validate data after checking for spaces
    if (!validateData()) return;

    // Validate URLs for selected social media inputs
    if (currentQuestion === 2) {
      for (const social of selectedSocials) {
        const inputElement = document.querySelector(`input[placeholder="${social} URL"]`);
        if (inputElement) { // Check if the input element exists
          const url = inputElement.value;
          if (!isValidURL(url)) {
            alert(`Please enter a valid URL for ${social}.`);
            return;
          }
        } else {
          // Only show this alert if the social icon is selected
          alert(`Input for ${social} not found.`);
          return;
        }
      }
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      handleSubmit(e); // Call handleSubmit
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
        const userData = {
            username,
            genrePreference: selectedGenres.join(','),
            contentPlatforms: selectedSocials.join(','),
            locationAnswer: selectedRegion,
            language: selectedLanguages.join(','),
            creatorBio,
            featureContent,
            socialUrls, // Use the updated socialUrls state directly
        };

        await update(ref(database, 'users/' + userIdFromState), userData);
        console.log('User data updated successfully:', userData);
        navigate(`/creator-page/${userIdFromState}`);
    } catch (error) {
        console.error('Error updating user data:', error);
        alert('Failed to update user data: ' + error.message);
    } finally {
        setLoading(false);
    }
};

  const progress = ((currentQuestion + 1) / questions.length) * 100;

  const handleDisplayNameChange = (e) => {
    const value = e.target.value.replace(/\s+/g, ''); // Remove spaces
    setUsername(value);
    questions[currentQuestion].setAnswer(value); // Update the answer in the questions array
  };

  const handleGenreSelect = (genre) => {
    setSelectedGenres((prev) => {
      if (prev.includes(genre)) {
        return prev.filter((g) => g !== genre); // Deselect if already selected
      } else {
        return [...prev, genre]; // Select the genre
      }
    });
  };

  const handleSocialSelect = (social) => {
    setSelectedSocials((prev) => {
      if (prev.includes(social)) {
        const newSocialUrls = { ...socialUrls };
        delete newSocialUrls[social]; // Remove URL if already selected
        setSocialUrls(newSocialUrls);
        return prev.filter((s) => s !== social); // Deselect if already selected
      } else {
        return [...prev, social]; // Select the social
      }
    });
  };

  const handleSocialsChange = (e, social) => {
    const { value } = e.target;
    setSocialUrls((prev) => ({
      ...prev,
      [social]: value, // Update the URL for the specific social platform
    }));
  };

  const handleMapClick = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;

    // Log the coordinates to the console
    console.log(`Clicked coordinates: X: ${offsetX}, Y: ${offsetY}`);

    // username coordinates on the screen (optional)
    setClickedCoordinates({ x: offsetX, y: offsetY });

    // Example coordinates for regions (you will need to adjust these)
    if (isInRegion(offsetX, offsetY, 'NA')) {
      setMap(WorldNA);
      setSelectedRegion('NA');
    } else if (isInRegion(offsetX, offsetY, 'EU')) {
      setMap(WorldEU);
      setSelectedRegion('EU');
    } else if (isInRegion(offsetX, offsetY, 'EAS')) {
      setMap(WorldEAS);
      setSelectedRegion('EAS');
    } else if (isInRegion(offsetX, offsetY, 'SA')) {
      setMap(WorldSA);
      setSelectedRegion('SA');
    } else if (isInRegion(offsetX, offsetY, 'AU')) {
      setMap(WorldAU);
      setSelectedRegion('AU');
    } else if (isInRegion(offsetX, offsetY, 'CAS')) {
      setMap(WorldCAS);
      setSelectedRegion('CAS');
    } else if (isInRegion(offsetX, offsetY, 'ME')) {
      setMap(WorldME);
      setSelectedRegion('ME');
    } else if (isInRegion(offsetX, offsetY, 'NAF')) {
      setMap(WorldNAF);
      setSelectedRegion('NAF');
    } else if (isInRegion(offsetX, offsetY, 'RU')) {
      setMap(WorldRU);
      setSelectedRegion('RU');
    } else if (isInRegion(offsetX, offsetY, 'SAF')) {
      setMap(WorldSAF);
      setSelectedRegion('SAF');
    } else if (isInRegion(offsetX, offsetY, 'SAS')) {
      setMap(WorldSAS);
      setSelectedRegion('SAS');
    } else if (isInRegion(offsetX, offsetY, 'SEA')) {
      setMap(WorldSEA);
      setSelectedRegion('SEA');
    } else if (isInRegion(offsetX, offsetY, 'CA')) {
      setMap(WorldCA);
      setSelectedRegion('CA');
    }
  };

  const isInRegion = (x, y, region) => {
    const isMobile = window.innerWidth <= 480; // Mobile breakpoint
    const isTablet = window.innerWidth >= 1450; // Tablet breakpoint

    // Define the clickable areas for each region based on coordinates
    switch (region) {
      case 'NA': // North America
        if (isMobile) {
          return x > 9 && x < 147 && y > 45 && y < 165; // Mobile coordinates
        } else if (isTablet) {
          return x > 203 && x < 503 && y > 11 && y < 259; // Tablet coordinates
        } else {
          return x > 196 && x < 411 && y > 15 && y < 193; // Desktop coordinates
        }
      case 'EU': // Europe
        if (isMobile) {
          return x > 157 && x < 222 && y > 55 && y < 152; // Mobile coordinates
        } else if (isTablet) {
          return x > 513 && x < 639 && y > 59 && y < 238; // Tablet coordinates
        } else {
          return x > 418 && x < 502 && y > 35 && y < 180; // Desktop coordinates
        }
      case 'EAS': // East Asia
        if (isMobile) {
          return x > 250 && x < 302 && y > 144 && y < 172; // Mobile coordinates
        } else if (isTablet) {
          return x > 695 && x < 797 && y > 221 && y < 275; // Tablet coordinates
        } else {
          return x > 546 && x < 617 && y > 161 && y < 204; // Desktop coordinates
        }
      case 'SA': // South America
        if (isMobile) {
          return x > 84 && x < 139 && y > 192 && y < 260; // Mobile coordinates
        } else if (isTablet) {
          return x > 376 && x < 482 && y > 304 && y < 445; // Tablet coordinates
        } else {
          return x > 311 && x < 381 && y > 222 && y < 325; // Desktop coordinates
        }
      case 'AU': // Australia
        if (isMobile) {
          return x > 271 && x < 339 && y > 208 && y <248; // Mobile coordinates
        } else if (isTablet) {
          return x > 740 && x < 866 && y > 328 && y < 421; // Tablet coordinates
        } else {
          return x > 575 && x < 666 && y > 251 && y < 311; // Desktop coordinates
        }
      case 'CAS': // Central Asia
        if (isMobile) {
          return x > 215 && x < 249 && y > 148 && y < 169; // Mobile coordinates
        } else if (isTablet) {
          return x > 626 && x < 697 && y > 223 && y < 267; // Tablet coordinates
        } else {
          return x > 496 && x < 548 && y > 168 && y < 195; // Desktop coordinates
        }
      case 'ME': // Middle East
        if (isMobile) {
          return x > 197 && x < 226 && y > 164 && y < 188; // Mobile coordinates
        } else if (isTablet) {
          return x > 595 && x < 640 && y > 255 && y < 302; // Tablet coordinates
        } else {
          return x > 476 && x < 505 && y > 191 && y < 221; // Desktop coordinates
        }
      case 'NAF': // North Africa
        if (isMobile) {
          return x > 147 && x < 205 && y > 160 && y < 184; // Mobile coordinates
        } else if (isTablet) {
          return x > 497 && x < 602 && y > 251 && y < 294; // Tablet coordinates
        } else {
          return x > 401 && x < 473 && y > 184 && y < 220; // Desktop coordinates
        }
      case 'RU': // Russia
        if (isMobile) {
          return x > 230 && x < 351 && y > 59 && y < 150; // Mobile coordinates
        } else if (isTablet) {
          return x > 654 && x < 889 && y > 54 && y < 233; // Tablet coordinates
        } else {
          return x > 515 && x < 688 && y > 41 && y < 173; // Desktop coordinates
        }
      case 'SAF': // Sub-Saharan Africa
        if (isMobile) {
          return x > 149 && x < 219 && y > 185 && y < 231; // Mobile coordinates
        } else if (isTablet) {
          return x > 500 && x < 623 && y > 297 && y < 388; // Tablet coordinates
        } else {
          return x > 405 && x < 494 && y > 220 && y < 286; // Desktop coordinates
        }
      case 'SAS': // South Asia
        if (isMobile) {
          return x > 234 && x < 252 && y > 166 && y < 192; // Mobile coordinates
        } else if (isTablet) {
          return x > 664 && x < 699 && y > 262 && y < 306; // Tablet coordinates
        } else {
          return x > 523 && x < 549 && y > 193 && y < 229; // Desktop coordinates
        }
      case 'SEA': // South-East Asia
        if (isMobile) {
          return x > 253 && x < 303 && y > 171 && y < 202; // Mobile coordinates
        } else if (isTablet) {
          return x > 696 && x < 803 && y > 273 && y < 330; // Tablet coordinates
        } else {
          return x > 549 && x < 621 && y > 201 && y < 243; // Desktop coordinates
        }
      case 'CA': // Central America
        if (isMobile) {
          return x > 40 && x < 167 && y > 166 && y < 185; // Mobile coordinates
        } else if (isTablet) {
          return x > 317 && x < 410 && y > 260 && y < 298; // Tablet coordinates
        } else {
          return x > 267 && x < 335 && y > 192 && y < 221; // Desktop coordinates
        }
      default:
        return false;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      await setPersistence(auth, browserSessionPersistence); // Clear persistence
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const renderLanguageList = () => {
    return (
      <ul className="language-suggestions">
        {filteredLanguages.map((lang) => (
          <li key={lang.name} onClick={() => handleLanguageSelect(lang)}>
            <ReactFlagsSelect
              countries={[lang.code]}
              customLabels={{ [lang.code]: lang.name }}
              selected={lang.code}
              placeholder=""
              className="flag-select"
            />
          </li>
        ))}
      </ul>
    );
  };

  const storage = getStorage(); // Initialize Firebase Storage

  const handleProfilePicUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `profilePics/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            await update(ref(database, 'users/' + userIdFromState), { profilePic: downloadURL }); // Store the URL in the database
            setProfilePicUploaded(true); // Set the state to indicate upload success
            console.log('Profile picture uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading profile picture:', error);
        }
    }
  };

  const handleProfileBannerUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `profileBanners/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            await update(ref(database, 'users/' + userIdFromState), { profileBanner: downloadURL }); // Store the URL in the database
            setProfileBannerUploaded(true); // Set the state to indicate upload success
            console.log('Profile banner uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading profile banner:', error);
        }
    }
  };

  const handleLinkChange = (e, index) => {
    const { value } = e.target;
    const updatedLinks = [...featureContent.split(',')];
    updatedLinks[index] = value;
    setFeatureContent(updatedLinks.join(','));
  };

  const handleAddLink = () => {
    if (linkCount < 5) {
      setLinkCount(linkCount + 1); // Increment the link count
    }
  };

  const handleRemoveLink = () => {
    if (linkCount > 1) {
      setLinkCount(linkCount - 1); // Decrement the link count
    }
  };

  return (
    <div className={`creator-typeform ${currentQuestion === 0 ? 'username-name-question' : ''}`}>
        <div className="logo-container" ref={logoRef}> {/* Attach ref to the logo */}
          <div className="logo-and-logout">
            <img src={logo} alt="Logo" className="typeform-logo" />
            <div className="logout-button-container">
              <button className="logout-button nav-button" onClick={handleLogout}>
            Sign Out
          </button>
            </div>
          </div>
      </div>
      {loading ? (
        <div className="loading-container">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p>Building...</p>
        </div>
      ) : (
        <>
          <h1 className='typeform-title'>{questions[currentQuestion].question}</h1>
          {currentQuestion === 1 && (
            <p className="question-description genre-question-description">Select all that apply.</p>
          )}
          {currentQuestion === 2 && (
            <>
              <p className="social-question-description">Select all that you plan to use.</p>
              <div className="socials-container">
                {socialMediaList.map((social) => (
                  <div
                    key={social.name}
                    className={`social-item ${selectedSocials.includes(social.name) ? 'selected' : ''}`}
                    onClick={() => handleSocialSelect(social.name)}
                  >
                    <img
                      src={selectedSocials.includes(social.name) ? require(`../assets/socials/${social.selectedIcon}`) : require(`../assets/socials/${social.icon}`)}
                      alt={social.name}
                      className={`social-icon ${social.name === 'YouTube' ? 'youtube-icon' : ''} ${social.name === 'X' ? 'x-icon' : ''}`}
                    />
                    <p className={`social-label ${social.name === 'YouTube' ? 'youtube-label' : ''} ${social.name === 'X' ? 'x-label' : ''}`}>{social.name}</p>
                    {selectedSocials.includes(social.name) && (
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder={`${social.name} URL`}
                          className="social-input"
                          required
                          onChange={(e) => handleSocialsChange(e, social.name)} // Update URL on change
                          onClick={(e) => e.stopPropagation()} // Prevent click from propagating
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          {currentQuestion === 1 && (
            <div className="genres-container genre-select-question">
              {genresList.map((genre) => (
                <div
                  key={genre.name}
                  className={`genre-item ${selectedGenres.includes(genre.name) ? 'selected' : ''}`}
                  onClick={() => handleGenreSelect(genre.name)}
                >
                  <img
                    src={selectedGenres.includes(genre.name) ? genre.selectedIcon : genre.icon}
                    alt={genre.name}
                    className="genre-icon"
                  />
                  <p className={`genre-label ${selectedGenres.includes(genre.name) ? 'selected-label' : ''}`}>
                    {genre.name}
                  </p>
                </div>
              ))}
            </div>
          )}
          {currentQuestion === 3 && (
            <div className='region-container'>
              <select className='region-select' value={selectedRegion} onChange={handleRegionChange}>
                <option value="">Select a region</option>
                <option value="NA">North America</option>
                <option value="EU">Europe</option>
                <option value="EAS">East Asia</option>
                <option value="SA">South America</option>
                <option value="AU">Australia and PI</option>
                <option value="CAS">Central Asia</option>
                <option value="ME">Middle East</option>
                <option value="NAF">North Africa</option>
                <option value="RU">Russia</option>
                <option value="SAF">Sub-Saharan Africa</option>
                <option value="SAS">South Asia</option>
                <option value="SEA">South-East Asia</option>
                <option value="CA">Central America</option>
                {/* Add other regions as options */}
              </select>
              <img className='world-map' src={map} alt="World Map" onClick={handleMapClick} />
              

              
              {/* username clicked coordinates */}
              <div>
                <p className='clicked-coordinates'>Clicked Coordinates: X: {clickedCoordinates.x}, Y: {clickedCoordinates.y}</p>
              </div>
            </div>
          )}
          {currentQuestion !== 1 && currentQuestion !== 2 && currentQuestion !== 3 && currentQuestion !== 4 && currentQuestion !== 5 &&( // Exclude question 4
            <div className="input-container">
              <input
                type="text"
                value={questions[currentQuestion].answer || ''}
                onChange={handleDisplayNameChange}
                placeholder="YOUR HANDLE"
                required  
                className={`input 
                    ${currentQuestion === 0 && questions[currentQuestion].answer && isUsernameUnique ? 'unique' : ''} 
                    ${currentQuestion === 0 && !isUsernameUnique ? 'error' : ''} 
                    ${currentQuestion === 0 && !questions[currentQuestion].answer ? 'empty' : ''}`}
              />  
              {currentQuestion === 0 && !isUsernameUnique && (
                <p className="username-error-message">This username is taken</p>
              )}
            </div>
          )}
          {currentQuestion === 4 && ( // Keep only the language input container for question 4
            <div className="language-input-container">
              <input
                type="text"
                value={languageInput}
                onChange={handleLanguageChange}
                placeholder="Type a language..."
              />
              {filteredLanguages.length > 0 && renderLanguageList()}
            </div>
          )}
          {currentQuestion === 5 && (
            <div className="question-five-container">
              <div className="bio-container">
                <textarea
                  placeholder="Share a bit about yourself and complete your bio!... "
                  className="bio-input"
                  onChange={(e) => setCreatorBio(e.target.value)}
                />
              </div>
              <div className="upload-container">
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleProfilePicUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={profilePicUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Pic"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>
                      <p className='upload-label'>Add Profile Pic</p>
                      <p className='label-sub'>(800 x 800 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleProfileBannerUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />
                    <img
                      src={profileBannerUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Banner"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>  
                      <p className='upload-label'>Add Profile Banner</p>
                      <p className='label-sub'>(1500 x 500 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className="link-inputs">
                  <p className='upload-label'>Feature your best content</p>
                  <p className='upload-label-sub'>add up to 4 social post links</p>
                  {Array.from({ length: linkCount }, (_, index) => (
                    <div key={index} className="link-input-container">
                      <input
                        type="text"
                        placeholder={`Link ${index + 1}`}
                        onChange={(e) => handleLinkChange(e, index)}
                      />
                      {index > 0 && ( // Only show the remove icon if it's not the first link
                        <span
                          className="remove-link-icon"
                          onClick={handleRemoveLink}
                          style={{ cursor: 'pointer'}} // Increased width
                        >
                          -
                        </span>
                      )}
                    </div>
                  ))}
                  {linkCount < 4 && ( // Only show the add link icon if less than 5 links
                    <img
                      src={addLinkIcon}
                      alt="Add Link"
                      className="add-link-icon"
                      onClick={handleAddLink} // Trigger adding a new link input
                      style={{ cursor: 'pointer'}} // Style the icon
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <form className='form-container' onSubmit={handleNext}>
            <div className='creator-typeform-buttons'>
              <button type="button" className="back-button" onClick={handleBack} disabled={currentQuestion === 0} >
                <img src={backIcon} alt="Back" className="backIcon" />
                BACK
              </button>
              <button className="next-button" type="submit" onClick={handleNext} style={{ width: currentQuestion === 5 ? '140px' : ' ' }}>
                {currentQuestion < questions.length - 1 ? "NEXT" : "FINISH"}
                <img src={continueIcon} alt="Continue" className="icon" />
              </button>
            </div>
          </form>
          <ProgressBar className='progress-bar' progress={progress} />
        </>
      )}
    </div>
  );
};

export default CreatorTypeform;
