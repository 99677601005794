import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo_title.png'; // Import the logo image
import './StudioTypeform.css';
import { ref, update, get } from "firebase/database"; // Import Firebase functions
import { database } from '../firebase'; // Import your Firebase database
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner component
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
import continueIcon from '../assets/continue-icon.svg'; // Import the SVG icon
import backIcon from '../assets/chevron-left.svg';
import Adventureicon from '../assets/genres/adventure-dark.svg';
import Battleroyalicon from '../assets/genres/battleroyale-dark.svg';
import Fightingicon from '../assets/genres/fighting-dark.svg';
import FPSicon from '../assets/genres/fps-dark.svg';
import Minigamesicon from '../assets/genres/minigames-dark.svg';
import Racingicon from '../assets/genres/racing-dark.svg';
import RPGicon from '../assets/genres/rpg-dark.svg';
import Strategyicon from '../assets/genres/strategy-dark.svg';
import Survivalicon from '../assets/genres/survival-dark.svg';
import AdventureSelected from '../assets/genres/Adventure.svg';
import BattleroyalSelected from '../assets/genres/Battle-Royale.svg';
import FightingSelected from '../assets/genres/Fighting.svg';
import FPSSelected from '../assets/genres/FPS.svg';
import MinigamesSelected from '../assets/genres/Minigames.svg';
import RacingSelected from '../assets/genres/Racing-icon.svg';
import RPGSelected from '../assets/genres/RPG.svg';
import StrategySelected from '../assets/genres/Strategy.svg';
import SurvivalSelected from '../assets/genres/Survival.svg';
import WorldMap from '../assets/regions/world-dark.svg'; // Default world map
import WorldNA from '../assets/regions/world-NA.svg'; // North America map
import WorldEU from '../assets/regions/world-EU.svg'; // Europe map
import WorldEAS from '../assets/regions/world-EAS.svg'; // East Asia map
import WorldSA from '../assets/regions/world-SA.svg'; // South America map
import WorldAU from '../assets/regions/world-AU.svg'; // Australia and PI map
import WorldCAS from '../assets/regions/world-CAS.svg'; // Central Asia map
import WorldME from '../assets/regions/world-ME.svg'; // Middle East map
import WorldNAF from '../assets/regions/world-NAF.svg'; // North Africa map
import WorldRU from '../assets/regions/world-RU.svg'; // Russia map
import WorldSAF from '../assets/regions/world-SAF.svg'; // Sub-Saharan Africa map
import WorldSAS from '../assets/regions/world-SAS.svg'; // South Asia map
import WorldSEA from '../assets/regions/world-SEA.svg'; // South-East Asia map
import WorldCA from '../assets/regions/world-CA.svg'; // Central America map
// Import other region maps as needed
import { auth } from '../firebase'; // Import Firebase auth
import { signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import downloadIcon from '../assets/images/Download-icon.svg'; // Path to your download icon
import checkIcon from '../assets/images/Check-icon.svg'; // Path to your check icon
import addLinkIcon from '../assets/images/add-link.svg'; // Import the SVG icon
import yesIcon from '../assets/decision-circles/check-circle-dark.svg'; // Import the SVG icon
import noIcon from '../assets/decision-circles/x-circle-dark.svg'; // Import the SVG icon
import yesSelectedIcon from '../assets/decision-circles/check-circle-light.svg'; // Import the SVG icon
import noSelectedIcon from '../assets/decision-circles/x-circle-light.svg'; // Import the SVG icon
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase Storage functions

const genresList = [
  { name: 'Adventure', icon: Adventureicon, selectedIcon: AdventureSelected },
  { name: 'Battle Royale', icon: Battleroyalicon, selectedIcon: BattleroyalSelected },
  { name: 'Fighting', icon: Fightingicon, selectedIcon: FightingSelected },
  { name: 'FPS', icon: FPSicon, selectedIcon: FPSSelected },
  { name: 'Minigames', icon: Minigamesicon, selectedIcon: MinigamesSelected },
  { name: 'Racing', icon: Racingicon, selectedIcon: RacingSelected },
  { name: 'RPG', icon: RPGicon, selectedIcon: RPGSelected },
  { name: 'Strategy', icon: Strategyicon, selectedIcon: StrategySelected },
  { name: 'Survival', icon: Survivalicon, selectedIcon: SurvivalSelected },
];

const socialMediaList = [
  { name: 'instagram', icon: 'insta-dark.svg', selectedIcon: 'insta-light.svg' },
  { name: 'tiktok', icon: 'tiktok-dark.svg', selectedIcon: 'tiktok-light.svg' },
  { name: 'youtube', icon: 'youtube-dark.svg', selectedIcon: 'youtube-light.svg' },
  { name: 'x', icon: 'X-dark.svg', selectedIcon: 'X-light.svg' },
  { name: 'facebook', icon: 'facebook-dark.svg', selectedIcon: 'facebook-light.svg' },
  { name: "twitch", icon: "twitch-dark.svg", selectedIcon: "twitch-light.svg" },
];


const CreatorTypeform = () => {
  const location = useLocation();
  const { userId: userIdFromState } = location.state || { userId: null };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [username, setUsername] = useState('');
  const [selectedSocials, setSelectedSocials] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(''); // State for selected region
  const [map, setMap] = useState(WorldMap); // State for the current map
  const [studioBio, setStudioBio] = useState('');
  const [developmentStage, setDevelopmentStage] = useState('');
  const [studioResources, setStudioResources] = useState([]);
  const [recentNews, setRecentNews] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUsernameUnique, setIsUsernameUnique] = useState(true);
  const navigate = useNavigate();

  const logoRef = useRef(null);

  const [clickedCoordinates, setClickedCoordinates] = useState({ x: 0, y: 0 });
  const [studioProfilePicUploaded, setStudioProfilePicUploaded] = useState(false);
  const [gameProfilePicUploaded, setGameProfilePicUploaded  ] = useState(false);
  const [studioProfileBannerUploaded, setStudioProfileBannerUploaded] = useState(false);
  const [gameProfileBannerUploaded, setGameProfileBannerUploaded] = useState(false);
  const [gameDescription, setGameDescription] = useState('');
  const [gameTitles, setGameTitles] = useState([{ title: '', website: '', platforms: '', developmentStage: '' }]);
  const [linkCount, setLinkCount] = useState(1); // State to manage the number of link inputs
  const [socialUrls, setSocialUrls] = useState({}); // State to hold social URLs
  const [gameGenre, setGameGenre] = useState('');

  const [filteredPlatforms, setFilteredPlatforms] = useState([]);
  const [platformInput, setPlatformInput] = useState('');

  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const [addAnotherGame, setAddAnotherGame] = useState(null); // State to manage the final question response

  const [selectedDecision, setSelectedDecision] = useState(null); // State to track the selected decision

  const [gameContent, setGameContent] = useState([]); // State to hold uploaded game content URLs
  const [gameContent1Uploaded, setGameContent1Uploaded] = useState(false);
  const [gameContent2Uploaded, setGameContent2Uploaded] = useState(false);
  const [gameContent3Uploaded, setGameContent3Uploaded] = useState(false);
  const [gameContent4Uploaded, setGameContent4Uploaded] = useState(false);
  const [gameContent5Uploaded, setGameContent5Uploaded] = useState(false);

  useEffect(() => {
    if (window.self !== window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);

  const handleRegionChange = (event) => {
    const region = event.target.value;
    setSelectedRegion(region);

    // Update the map based on the selected region
    switch (region) {
      case 'NA':
        setMap(WorldNA);
        break;
      case 'EU':
        setMap(WorldEU); // Add the corresponding SVG for Europe
        break;
      case 'EAS':
        setMap(WorldEAS); // Add the corresponding SVG for East Asia
        break;
      case 'SA':
        setMap(WorldSA); // Add the corresponding SVG for South America
        break;
      case 'AU':
        setMap(WorldAU); // Add the corresponding SVG for Australia and PI
        break;
      case 'CAS':
        setMap(WorldCAS); // Add the corresponding SVG for Central Asia
        break;
      case 'ME':
        setMap(WorldME); // Add the corresponding SVG for Middle East
        break;
      case 'NAF':
        setMap(WorldNAF); // Add the corresponding SVG for North Africa
        break;
      case 'RU':
        setMap(WorldRU); // Add the corresponding SVG for Russia
        break;
      case 'SAF':
        setMap(WorldSAF); // Add the corresponding SVG for Sub-Saharan Africa
        break;
      case 'SAS':
        setMap(WorldSAS); // Add the corresponding SVG for South Asia
        break;
      case 'SEA':
        setMap(WorldSEA); // Add the corresponding SVG for South-East Asia
        break;
      case 'CA':
        setMap(WorldCA); // Add the corresponding SVG for Central America
        break;
      default:
        setMap(WorldMap);
    }
  };

  useEffect(() => {
    const checkUsernameUnique = async () => {
      if (currentQuestion === 0 && username) { // Only check if it's the username question
        const snapshot = await get(ref(database, 'users'));
        const users = snapshot.val();
        const existingNames = Object.values(users)
          .map(user => user.username ? user.username.toLowerCase() : null) // Check if username exists
          .filter(username => username !== null); // Filter out null values
        setIsUsernameUnique(!existingNames.includes(username.toLowerCase())); // Convert input username to lowercase
      } else {
        setIsUsernameUnique(true);
      }
    };

    const handler = setTimeout(() => {
      checkUsernameUnique();
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [username, currentQuestion]); // Add currentQuestion to the dependency array

  useEffect(() => {
    // Scroll to the logo when the current question changes
    if (currentQuestion >= 0 && logoRef.current) {
      const logoPosition = logoRef.current.getBoundingClientRect().top + window.scrollY;
      const offset = 50; // Adjust this value to set how high above the logo you want to scroll
      window.scrollTo({
        top: logoPosition - offset,
        behavior: 'smooth'
      });
    }
  }, [currentQuestion]);

  useEffect(() => {
  }, [currentQuestion]);

  if (!userIdFromState) {
    console.error('User ID is undefined');
    return <div>Error: User ID is not available.</div>;
  }

  const questions = [
    { question: "Your studio's username", answer: username, setAnswer: setUsername },
    { question: "Where is your studio based?", answer: selectedRegion, setAnswer: setSelectedRegion },
    { question: "Tell us more about your studio.", answer: studioBio, setAnswer: setStudioBio },
    { question: "Let's add your first game title!", answer: gameTitles, setAnswer: () => {} },
    { question: "What is your game's genre?", answer: gameGenre, setAnswer: setGameGenre },
    { question: "Where do you post updates?", answer: selectedSocials, setAnswer: setSelectedSocials },
    { question: "Let's finish your game profile!", answer: '', setAnswer: () => {} },
    { question: "Do you want to add another game title?", answer: addAnotherGame, setAnswer: setAddAnotherGame }
  ];

  const validateData = () => {
    if (currentQuestion === 0 && !username) {
      alert("Please fill in your username.");
      return false;
    }
    if (currentQuestion === 0 && !isUsernameUnique) {
      alert("Username must be unique.");
      return false;
    }
    if (currentQuestion === 4 && !gameGenre.length) {
      alert("Please select at least one genre.");
      return false;
    }
    if (currentQuestion === 5 && selectedSocials.length === 0) {
      alert("Please select at least one social media platform.");
      return false;
    }
    if (currentQuestion === 1 && !selectedRegion) {
      alert("Please select a region.");
      return false;
    }
    if (currentQuestion === 2) {
      const urls = recentNews.split(',').map(url => url.trim());
      if (urls.length > 5) {
        alert("Please add up to 5 links only.");
        return false;
      }
      for (let url of urls) {
        if (!isValidURL(url)) {
          alert("Please enter valid URLs.");
          return false;
        }
      }
    }
    return true; // All validations passed
  };

  const isValidURL = (string) => {
    const res = string.match(/(https?:\/\/[^\s]+)/g);
    return (res !== null);
  };

  const handleNext = async (e) => {
    e.preventDefault();

    // Check for spaces in the username name
    if (currentQuestion === 0 && username.trim().includes(' ')) {
      alert("Username name cannot contain spaces.");
      return;
    }

    // Validate data after checking for spaces
    if (!validateData()) return;

    // Validate URLs for selected social media inputs
    if (currentQuestion === 5) {
      for (const social of selectedSocials) {
        const inputElement = document.querySelector(`input[placeholder="${social} URL"]`);
        if (inputElement) { // Check if the input element exists
          const url = inputElement.value;
          if (!isValidURL(url)) {
            alert(`Please enter a valid URL for ${social}.`);
            return;
          }
        } else {
          // Only show this alert if the social icon is selected
          alert(`Input for ${social} not found.`);
          return;
        }
      }
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      handleFinalQuestion(); // Handle the final question logic
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
        e.preventDefault(); // Only call preventDefault if e is defined
    }
    setLoading(true);

    try {
        const userData = {
          location: selectedRegion,
          studioBio,
          studioProfilePic: studioProfilePicUploaded || '',
          studioProfileBanner: studioProfileBannerUploaded || '',
          studioResources: Array.isArray(studioResources) ? studioResources.join(',') : '', // Ensure it's an array before joining
          [username]: {
                games: {
                    [gameTitles[0].title]: {
                        website: gameTitles[0].website,
                        platforms: Array.isArray(selectedPlatforms) ? selectedPlatforms.join(',') : '', // Check if selectedPlatforms is an array
                        developmentStage: developmentStage,
                        gameGenre: Array.isArray(gameGenre) ? gameGenre.join(',') : '', // Check if gameGenre is an array
                        gameContentPlatforms: Array.isArray(gameTitles[0].selectedSocials) ? gameTitles[0].selectedSocials.join(',') : '', // Check if selectedSocials is an array
                        socialUrls: socialUrls || {}, // Ensure socialUrls is defined
                        description: gameDescription,
                        gameProfilePic: gameProfilePicUploaded || '',
                        gameProfileBanner: gameProfileBannerUploaded || '',
                        gameContent: gameContent, // Store the array of game content URLs
                      },
                },
            },
        };

        // Log the userData to check for undefined values
        console.log('User data before update:', userData);

        await update(ref(database, 'users/' + userIdFromState), userData);
        console.log('User data updated successfully:', userData);
        navigate(`/studio-page/${userIdFromState}`);
    } catch (error) {
        console.error('Error updating user data:', error);
        alert('Failed to update user data: ' + error.message);
    } finally {
        setLoading(false);
    }
};

  const progress = ((currentQuestion + 1) / questions.length) * 100;

  const handleDisplayNameChange = (e) => {
    const value = e.target.value.replace(/\s+/g, ''); // Remove spaces
    setUsername(value);
    questions[currentQuestion].setAnswer(value); // Update the answer in the questions array
  };

  const handleGenreSelect = (genreName) => {
    setGameGenre((prevGenres) => {
        if (prevGenres.includes(genreName)) {
            // If the genre is already selected, remove it
            const updatedGenres = prevGenres.filter((genre) => genre !== genreName);
            console.log('Genres after deselection:', updatedGenres); // Log the updated genres
            return updatedGenres;
        } else {
            // If the genre is not selected, add it
            const updatedGenres = [...prevGenres, genreName];
            console.log('Genres after selection:', updatedGenres); // Log the updated genres
            return updatedGenres;
        }
    });
  };

  const handleSocialSelect = (social) => {
    setSelectedSocials((prev) => {
      if (prev.includes(social)) {
        const newSocialUrls = { ...socialUrls };
        delete newSocialUrls[social]; // Remove URL if already selected
        setSocialUrls(newSocialUrls);
        return prev.filter((s) => s !== social); // Deselect if already selected
      } else {
        return [...prev, social]; // Select the social
      }
    });
  };

  const handleSocialsChange = (e, social) => {
    const { value } = e.target;
    setSocialUrls((prev) => ({
      ...prev,
      [social]: value, // Update the URL for the specific social platform
    }));
  };

  const handleMapClick = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;

    // Log the coordinates to the console
    console.log(`Clicked coordinates: X: ${offsetX}, Y: ${offsetY}`);

    // username coordinates on the screen (optional)
    setClickedCoordinates({ x: offsetX, y: offsetY });

    // Example coordinates for regions (you will need to adjust these)
    if (isInRegion(offsetX, offsetY, 'NA')) {
      setMap(WorldNA);
      setSelectedRegion('NA');
    } else if (isInRegion(offsetX, offsetY, 'EU')) {
      setMap(WorldEU);
      setSelectedRegion('EU');
    } else if (isInRegion(offsetX, offsetY, 'EAS')) {
      setMap(WorldEAS);
      setSelectedRegion('EAS');
    } else if (isInRegion(offsetX, offsetY, 'SA')) {
      setMap(WorldSA);
      setSelectedRegion('SA');
    } else if (isInRegion(offsetX, offsetY, 'AU')) {
      setMap(WorldAU);
      setSelectedRegion('AU');
    } else if (isInRegion(offsetX, offsetY, 'CAS')) {
      setMap(WorldCAS);
      setSelectedRegion('CAS');
    } else if (isInRegion(offsetX, offsetY, 'ME')) {
      setMap(WorldME);
      setSelectedRegion('ME');
    } else if (isInRegion(offsetX, offsetY, 'NAF')) {
      setMap(WorldNAF);
      setSelectedRegion('NAF');
    } else if (isInRegion(offsetX, offsetY, 'RU')) {
      setMap(WorldRU);
      setSelectedRegion('RU');
    } else if (isInRegion(offsetX, offsetY, 'SAF')) {
      setMap(WorldSAF);
      setSelectedRegion('SAF');
    } else if (isInRegion(offsetX, offsetY, 'SAS')) {
      setMap(WorldSAS);
      setSelectedRegion('SAS');
    } else if (isInRegion(offsetX, offsetY, 'SEA')) {
      setMap(WorldSEA);
      setSelectedRegion('SEA');
    } else if (isInRegion(offsetX, offsetY, 'CA')) {
      setMap(WorldCA);
      setSelectedRegion('CA');
    }
  };

  const isInRegion = (x, y, region) => {
    const isMobile = window.innerWidth <= 480; // Mobile breakpoint
    const isTablet = window.innerWidth >= 1450; // Tablet breakpoint

    // Define the clickable areas for each region based on coordinates
    switch (region) {
      case 'NA': // North America
        if (isMobile) {
          return x > 9 && x < 147 && y > 45 && y < 165; // Mobile coordinates
        } else if (isTablet) {
          return x > 203 && x < 503 && y > 11 && y < 259; // Tablet coordinates
        } else {
          return x > 196 && x < 411 && y > 15 && y < 193; // Desktop coordinates
        }
      case 'EU': // Europe
        if (isMobile) {
          return x > 157 && x < 222 && y > 55 && y < 152; // Mobile coordinates
        } else if (isTablet) {
          return x > 513 && x < 639 && y > 59 && y < 238; // Tablet coordinates
        } else {
          return x > 418 && x < 502 && y > 35 && y < 180; // Desktop coordinates
        }
      case 'EAS': // East Asia
        if (isMobile) {
          return x > 250 && x < 302 && y > 144 && y < 172; // Mobile coordinates
        } else if (isTablet) {
          return x > 695 && x < 797 && y > 221 && y < 275; // Tablet coordinates
        } else {
          return x > 546 && x < 617 && y > 161 && y < 204; // Desktop coordinates
        }
      case 'SA': // South America
        if (isMobile) {
          return x > 84 && x < 139 && y > 192 && y < 260; // Mobile coordinates
        } else if (isTablet) {
          return x > 376 && x < 482 && y > 304 && y < 445; // Tablet coordinates
        } else {
          return x > 311 && x < 381 && y > 222 && y < 325; // Desktop coordinates
        }
      case 'AU': // Australia
        if (isMobile) {
          return x > 271 && x < 339 && y > 208 && y <248; // Mobile coordinates
        } else if (isTablet) {
          return x > 740 && x < 866 && y > 328 && y < 421; // Tablet coordinates
        } else {
          return x > 575 && x < 666 && y > 251 && y < 311; // Desktop coordinates
        }
      case 'CAS': // Central Asia
        if (isMobile) {
          return x > 215 && x < 249 && y > 148 && y < 169; // Mobile coordinates
        } else if (isTablet) {
          return x > 626 && x < 697 && y > 223 && y < 267; // Tablet coordinates
        } else {
          return x > 496 && x < 548 && y > 168 && y < 195; // Desktop coordinates
        }
      case 'ME': // Middle East
        if (isMobile) {
          return x > 197 && x < 226 && y > 164 && y < 188; // Mobile coordinates
        } else if (isTablet) {
          return x > 595 && x < 640 && y > 255 && y < 302; // Tablet coordinates
        } else {
          return x > 476 && x < 505 && y > 191 && y < 221; // Desktop coordinates
        }
      case 'NAF': // North Africa
        if (isMobile) {
          return x > 147 && x < 205 && y > 160 && y < 184; // Mobile coordinates
        } else if (isTablet) {
          return x > 497 && x < 602 && y > 251 && y < 294; // Tablet coordinates
        } else {
          return x > 401 && x < 473 && y > 184 && y < 220; // Desktop coordinates
        }
      case 'RU': // Russia
        if (isMobile) {
          return x > 230 && x < 351 && y > 59 && y < 150; // Mobile coordinates
        } else if (isTablet) {
          return x > 654 && x < 889 && y > 54 && y < 233; // Tablet coordinates
        } else {
          return x > 515 && x < 688 && y > 41 && y < 173; // Desktop coordinates
        }
      case 'SAF': // Sub-Saharan Africa
        if (isMobile) {
          return x > 149 && x < 219 && y > 185 && y < 231; // Mobile coordinates
        } else if (isTablet) {
          return x > 500 && x < 623 && y > 297 && y < 388; // Tablet coordinates
        } else {
          return x > 405 && x < 494 && y > 220 && y < 286; // Desktop coordinates
        }
      case 'SAS': // South Asia
        if (isMobile) {
          return x > 234 && x < 252 && y > 166 && y < 192; // Mobile coordinates
        } else if (isTablet) {
          return x > 664 && x < 699 && y > 262 && y < 306; // Tablet coordinates
        } else {
          return x > 523 && x < 549 && y > 193 && y < 229; // Desktop coordinates
        }
      case 'SEA': // South-East Asia
        if (isMobile) {
          return x > 253 && x < 303 && y > 171 && y < 202; // Mobile coordinates
        } else if (isTablet) {
          return x > 696 && x < 803 && y > 273 && y < 330; // Tablet coordinates
        } else {
          return x > 549 && x < 621 && y > 201 && y < 243; // Desktop coordinates
        }
      case 'CA': // Central America
        if (isMobile) {
          return x > 40 && x < 167 && y > 166 && y < 185; // Mobile coordinates
        } else if (isTablet) {
          return x > 317 && x < 410 && y > 260 && y < 298; // Tablet coordinates
        } else {
          return x > 267 && x < 335 && y > 192 && y < 221; // Desktop coordinates
        }
      default:
        return false;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      await setPersistence(auth, browserSessionPersistence); // Clear persistence
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const storage = getStorage(); // Initialize Firebase Storage

  const handleStudioProfilePicUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `studioProfilePics/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            await update(ref(database, 'users/' + userIdFromState), { profilePic: downloadURL }); // Store the URL in the database
            setStudioProfilePicUploaded(file); // Store the file object or its name
            console.log('Studio profile picture uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading studio profile picture:', error);
        }
    } else {
        console.error('No file selected for upload.');
    }
  };

  const handleGameProfilePicUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameProfilePics/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGameProfilePicUploaded(downloadURL); // Store the URL in state
            console.log('Game profile picture uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game profile picture:', error);
        }
    }
  };

  const handleGameProfileBannerUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameProfileBanners/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGameProfileBannerUploaded(downloadURL); // Store the URL in state
            console.log('Game profile banner uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game profile banner:', error);
        }
    }
  };
  const handleGameContentUpload1 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent1/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGameContent((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGameContent1Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 1 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 1:', error);
        }
    }
  };
  const handleGameContentUpload2 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent2/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGameContent((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGameContent2Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 2 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 2:', error);
        }
    }
  };

  const handleGameContentUpload3 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent3/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGameContent((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGameContent3Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 3 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 3:', error);
        }
    }
  };

  const handleGameContentUpload4 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent4/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGameContent((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGameContent4Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 4 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 4:', error);
        }
    }
  };

  const handleGameContentUpload5 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent5/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGameContent((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGameContent5Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 5 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 5:', error);
        }
    }
  };

  const handleLinkChange = (e, index) => {
    const { value } = e.target;
    const updatedLinks = [...recentNews.split(',')];
    updatedLinks[index] = value;
    setRecentNews(updatedLinks.join(','));
  };

  const handleAddLink = () => {
    if (linkCount < 5) {
      setLinkCount(linkCount + 1); // Increment the link count
    }
  };

  const handleRemoveLink = () => {
    if (linkCount > 1) {
      setLinkCount(linkCount - 1); // Decrement the link count
    }
  };

  const handleGameTitleChange = (index, field, value) => {
    const updatedGameTitles = [...gameTitles];
    updatedGameTitles[index][field] = value;
    setGameTitles(updatedGameTitles);
  };

  const handleRemoveGameTitle = (index) => {
    const updatedGameTitles = gameTitles.filter((_, i) => i !== index);
    setGameTitles(updatedGameTitles);
  };

  const handlePlatformInputChange = (e) => {
    const value = e.target.value;
    setPlatformInput(value);

    // Filter platforms based on input
    const filtered = ['Windows', 'Mac', 'Linux', 'iOS', 'Android'].filter(platform =>
        platform.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredPlatforms(filtered);
  };

  const handlePlatformSelect = (platform) => {
    if (!selectedPlatforms.includes(platform)) {
        const updatedPlatforms = [...selectedPlatforms, platform];
        setSelectedPlatforms(updatedPlatforms);
        setPlatformInput(updatedPlatforms.join(', ')); // Display selected platforms in the input
    }
    setFilteredPlatforms([]); // Clear suggestions after selection

  };

  // Logic to handle the final question response
  const handleFinalQuestion = () => {
    if (addAnotherGame === 'yes') {
        // Duplicate questions 3-6 for the new game title
        const newGameTitle = { title: '', website: '', platforms: '', developmentStage: '' };
        setGameTitles((prev) => [...prev, newGameTitle]);
        // Reset the relevant states for the new game
        setGameGenre([]);
        setSelectedSocials([]);
        setStudioBio('');
        setRecentNews('');
    }
  };

  const handleGameWebsiteChange = (index, field, value) => {
    const updatedGameTitles = [...gameTitles];
    updatedGameTitles[index][field] = value;
    setGameTitles(updatedGameTitles);
    console.log(`Game website for ${updatedGameTitles[index].title} set to:`, value);
  };

  const handleResourceLinksChange = (e, index) => {
    const { value } = e.target;
    const updatedLinks = [...studioResources];
    updatedLinks[index] = value;
    setStudioResources(updatedLinks);
    console.log(`Resource link ${index + 1} set to:`, value);
  };

  const handleStudioProfileBannerUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `studioProfileBanners/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file);
            const downloadURL = await getDownloadURL(storageReference);
            setStudioProfileBannerUploaded(downloadURL);
            console.log('Studio profile banner uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading studio profile banner:', error);
        }
    }
  };
  
  return (
    <div className={`creator-typeform ${currentQuestion === 0 ? 'username-name-question' : ''}`}>
        <div className="logo-container" ref={logoRef}> {/* Attach ref to the logo */}
          <div className="logo-and-logout">
            <img src={logo} alt="Logo" className="typeform-logo" />
            <div className="logout-button-container">
              <button className="logout-button nav-button" onClick={handleLogout}>
                Sign Out
              </button>
            </div>
          </div>
      </div>
      {loading ? (
        <div className="loading-container">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p>Building...</p>
        </div>
      ) : (
        <>
          <h1 className='typeform-title'>{questions[currentQuestion].question}</h1>
          {currentQuestion === 5 && (
            <>
              <p className="social-question-description">Select all that you use to post content and updates.</p>
              <div className="socials-container">
                {socialMediaList.map((social) => (
                  <div
                    key={social.name}
                    className={`social-item ${selectedSocials.includes(social.name) ? 'selected' : ''}`}
                    onClick={() => handleSocialSelect(social.name)}
                  >
                    <img
                      src={selectedSocials.includes(social.name) ? require(`../assets/socials/${social.selectedIcon}`) : require(`../assets/socials/${social.icon}`)}
                      alt={social.name}
                      className={`social-icon ${social.name === 'YouTube' ? 'youtube-icon' : ''} ${social.name === 'X' ? 'x-icon' : ''}`}
                    />
                    <p className={`social-label ${social.name === 'YouTube' ? 'youtube-label' : ''} ${social.name === 'X' ? 'x-label' : ''}`}>{social.name}</p>
                    {selectedSocials.includes(social.name) && (
                      <div className="input-container">
                        <input 
                          type="text"
                          placeholder={`${social.name} URL`}
                          className="social-input"
                          required
                          onChange={(e) => handleSocialsChange(e, social.name)} // Update URL on change
                          onClick={(e) => e.stopPropagation()} // Prevent click from propagating
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          {currentQuestion === 4 && (
            <>
              <p className="genre-question-description" style={{ marginTop: '0px'}}>Select all that apply.</p>
              <div className="genres-container genre-select-question">
                {genresList.map((genre) => (
                  <div
                  key={genre.name}
                  className={`genre-item ${gameGenre.includes(genre.name) ? 'selected' : ''}`}
                  onClick={() => handleGenreSelect(genre.name)}
                >
                  <img
                    src={gameGenre.includes(genre.name) ? genre.selectedIcon : genre.icon}
                    alt={genre.name}
                    className="genre-icon"
                  />
                  <p className={`genre-label ${gameGenre.includes(genre.name) ? 'selected-label' : ''}`}>
                    {genre.name}
                  </p>
                </div>
              ))}
              </div>
            </>
          )}
          {currentQuestion === 1 && (
            <div className='region-container'>
              <select className='region-select' value={selectedRegion} onChange={handleRegionChange}>
                <option value="">Select a region</option>
                <option value="NA">North America</option>
                <option value="EU">Europe</option>
                <option value="EAS">East Asia</option>
                <option value="SA">South America</option>
                <option value="AU">Australia and PI</option>
                <option value="CAS">Central Asia</option>
                <option value="ME">Middle East</option>
                <option value="NAF">North Africa</option>
                <option value="RU">Russia</option>
                <option value="SAF">Sub-Saharan Africa</option>
                <option value="SAS">South Asia</option>
                <option value="SEA">South-East Asia</option>
                <option value="CA">Central America</option>
                {/* Add other regions as options */}
              </select>
              <img className='world-map' src={map} alt="World Map" onClick={handleMapClick} />
              

              
              {/* username clicked coordinates */}
              <div>
                <p className='clicked-coordinates'>Clicked Coordinates: X: {clickedCoordinates.x}, Y: {clickedCoordinates.y}</p>
              </div>
            </div>
          )}
          {currentQuestion !== 1 && currentQuestion !== 2 && currentQuestion !== 3 && currentQuestion !== 4 && currentQuestion !== 5 && currentQuestion !== 6 && currentQuestion !== 7 && ( // Exclude question 4
            <div className="input-container">
              <input
                type="text"
                value={questions[currentQuestion].answer || ''}
                onChange={handleDisplayNameChange}
                placeholder="STUDIO USERNAME"
                required  
              className={`input ${currentQuestion === 0 && questions[currentQuestion].answer && isUsernameUnique ? 'unique' : ''} ${currentQuestion === 0 && !isUsernameUnique ? 'error' : ''}`}
            />  
            </div>
          )}
          {currentQuestion === 2 && (
            <div className="question-five-container">
              <div className="bio-container">
                <textarea
                  placeholder="Share a bit about your company and complete your bio!"
                  className="bio-input"
                  value={studioBio} // Bind the textarea value to studioBio state
                  onChange={(e) => setStudioBio(e.target.value)}
                />
              </div>
              <div className="upload-container">
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleStudioProfilePicUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={studioProfilePicUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Pic"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>
                      <p className='upload-label'>Add Profile Pic</p>
                      <p className='label-sub'>(800 x 800 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleStudioProfileBannerUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />
                    <img
                      src={studioProfileBannerUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Banner"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>  
                      <p className='upload-label'>Add Profile Banner</p>
                      <p className='label-sub'>(1500 x 500 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className="link-inputs">
                  <p className='upload-label'>Add Studio Resources</p>
                  <p className='upload-label-sub'>add up to 4 official links</p>
                  {Array.from({ length: linkCount }, (_, index) => (
                    <div key={index} className="link-input-container">
                      <input
                        type="text"
                        placeholder={`Link ${index + 1}`}
                        onChange={(e) => handleLinkChange(e, index)}
                      />
                      {index > 0 && ( // Only show the remove icon if it's not the first link
                        <span
                          className="remove-link-icon"
                          onClick={handleRemoveLink}
                          style={{ cursor: 'pointer'}} // Increased width
                        >
                          -
                        </span>
                      )}
                    </div>
                  ))}
                  {linkCount < 4 && ( // Only show the add link icon if less than 5 links
                    <img
                      src={addLinkIcon}
                      alt="Add Link"
                      className="add-link-icon"
                      onClick={handleAddLink} // Trigger adding a new link input
                      style={{ cursor: 'pointer'}} // Style the icon
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {currentQuestion === 3 && (
            <div className="game-titles-container">
                {gameTitles.map((game, index) => (
                    <div key={index} className="game-title-inputs">
                        <input
                            type="text"
                            placeholder="GAME TITLE"
                            value={game.title}
                            onChange={(e) => handleGameTitleChange(index, 'title', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="GAME WEBSITE"
                            value={game.website}
                            onChange={(e) => handleGameWebsiteChange(index, 'website', e.target.value)}
                        />
                        <div className="platform-input-container">
                            <input
                                type="text"
                                placeholder="COMPATIBLE PLATFORMS"
                                value={platformInput}
                                onChange={handlePlatformInputChange}
                            />
                            {filteredPlatforms.length > 0 && (
                                <ul className="platform-suggestions">
                                    {filteredPlatforms.map((platform) => (
                                        <li key={platform} style={{ cursor: 'pointer'}} onClick={() => handlePlatformSelect(platform, index)}>
                                            {platform}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <select
                            className="input"
                            style={{ fontSize: '16px' }}
                            value={developmentStage}
                            onChange={(e) => setDevelopmentStage(e.target.value)}
                        >
                            <option value="">Select Development Stage</option>
                            <option value="Coming Soon">Coming Soon</option>
                            <option value="Prototype">Prototype</option>
                            <option value="Closed Testing">Closed Testing</option>
                            <option value="Early Access">Early Access</option>
                            <option value="Open Alpha">Open Alpha</option>
                            <option value="Closed Alpha">Closed Alpha</option>
                            <option value="Open Beta">Open Beta</option>
                            <option value="Closed Beta">Closed Beta</option>
                            <option value="Full Game">Full Game</option>
                        </select>
                        {index > 0 && ( // Only show the remove icon if it's not the first game title
                            <button onClick={() => handleRemoveGameTitle(index)}>Remove</button>
                        )}
                    </div>
                ))}
            </div>
          )}
                    {currentQuestion === 6 && (
            <div className="question-five-container game-description-container">
              <div className="bio-container">
                <textarea
                  placeholder="Share a description of your game and what makes it unique! "
                  className="bio-input"
                  onChange={(e) => setGameDescription(e.target.value)}
                />
              </div>
                <div className="upload-container">
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGameProfilePicUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={gameProfilePicUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Pic"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>
                      <p className='upload-label'>Add Profile Pic</p>
                      <p className='label-sub'>(800 x 800 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleGameProfileBannerUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />
                    <img
                      src={gameProfileBannerUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Banner"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>  
                      <p className='upload-label'>Add Profile Banner</p>
                      <p className='label-sub'>(1500 x 500 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className='game-content-container' style={{ gap: '10px'}}>
                  <p className='upload-label'>Feature Your Game Content</p>
                  <p className='upload-label-sub'>add up to 5 videos or screenshots</p>
                  <div className='content-upload-container'>
                  <div className='game-content-item'>
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGameContentUpload1}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={gameContent1Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                    <div className='game-content-item'>
                      <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGameContentUpload2}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={gameContent2Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                  <div className='game-content-item'>
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGameContentUpload3}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={gameContent3Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                  <div className='game-content-item'>
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGameContentUpload4}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={gameContent4Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                  <div className='game-content-item'>
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGameContentUpload5}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={gameContent5Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                  </div>
                </div>
                <div className="link-inputs">
                  <p className='upload-label'>Share Your Recent News</p>
                  <p className='upload-label-sub'>add up to 4 links</p>
                  {Array.from({ length: linkCount }, (_, index) => (
                    <div key={index} className="link-input-container">
                      <input
                        type="text"
                        placeholder={`Link ${index + 1}`}
                        onChange={(e) => handleResourceLinksChange(e, index)}
                      />
                      {index > 0 && ( // Only show the remove icon if it's not the first link
                        <span
                          className="remove-link-icon"
                          onClick={handleRemoveLink}
                          style={{ cursor: 'pointer'}} // Increased width
                        >
                          -
                        </span>
                      )}
                    </div>
                  ))}
                  {linkCount < 4 && ( // Only show the add link icon if less than 5 links
                    <img
                      src={addLinkIcon}
                      alt="Add Link"
                      className="add-link-icon"
                      onClick={handleAddLink} // Trigger adding a new link input
                      style={{ cursor: 'pointer'}} // Style the icon
                    />
                  )}
                </div>
                
              </div>
            </div>
          )}
          {currentQuestion === 7 && (
            <div className='question-seven-container'>
              <div className='decision-container'>
                <div 
                  className='decision-yes' 
                  style={{ cursor: 'pointer'}}
                  onClick={() => {
                    setSelectedDecision('yes');
                    setAddAnotherGame('yes'); // Update the state for adding another game
                    const newGameTitle = { title: '', website: '', platforms: '', developmentStage: '' };
                    setGameTitles((prev) => [...prev, newGameTitle]); // Add a new game title
                    setCurrentQuestion(3); // Redirect to the duplicated questions (3-6)
                  }}
                >
                  <img src={selectedDecision === 'yes' ? yesSelectedIcon : yesIcon} alt="Yes" className="decision-icon" />
                  <h2 className='decision-label'>Add Another Game</h2>
                </div>
                <div 
                  className='decision-no' 
                  style={{ cursor: 'pointer'}}
                  onClick={() => {
                    handleSubmit(); // Submit the form without an event
                  }}
                >
                  <img src={selectedDecision === 'no' ? noSelectedIcon : noIcon} alt="No" className="decision-icon" />
                  <h2 className='decision-label'>Build My Profile</h2>
                </div>
              </div>
            </div>
          )}
          <form className='form-container' onSubmit={handleNext}>
            <div className='creator-typeform-buttons'>
              <button type="button" className="back-button" onClick={handleBack} disabled={currentQuestion === 0} >
                <img src={backIcon} alt="Back" className="backIcon" />
                BACK
              </button>
              <button className="next-button" type="submit" onClick={handleNext}>
                {currentQuestion < questions.length - 1 ? "NEXT" : " "}
                <img src={continueIcon} alt="Continue" className="icon" />
              </button>
            </div>
          </form>
          <ProgressBar className='progress-bar' progress={progress} />
        </>
      )}
    </div>
  );
};

export default CreatorTypeform;
