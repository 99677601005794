import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebase';
import { ref, get } from "firebase/database";
import { Link, useNavigate } from 'react-router-dom';
import './SignUpSelection.css';
import continueIcon from '../assets/continue-icon.svg'; // Import the SVG icon

const SignUpSelection = () => {
  const [selectedOption, setSelectedOption] = useState(null); // State to track selected option
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // Check if the app is embedded in an iframe
    if (window.self !== window.top) {
      window.top.location.href = window.location.href; // Redirect the parent window to the current page
    }

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = ref(database, 'users/' + user.uid);
        const snapshot = await get(userRef);
        const userData = snapshot.val();

        if (userData) {
          if (userData.signedUp) {
            navigate(`/creator-page/${user.uid}`);
          } else {
            if (userData.userType === 'creator') {
              navigate('/creator-typeform', { state: { userId: user.uid } });
            } else if (userData.userType === 'studio') {
              navigate('/studio-typeform', { state: { userId: user.uid } });
            }
          }
        }
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  const handleButtonClick = (option) => {
    // Toggle selection
    if (selectedOption === option) {
      setSelectedOption(null); // Deselect if already selected
    } else {
      setSelectedOption(option); // Select the new option
    }
  };

  const handleContinue = () => {
    if (selectedOption === 'creator') {
      navigate('/signup-creator'); // Navigate to Creator Profile
    } else if (selectedOption === 'studio') {
      navigate('/signup-studio'); // Navigate to Studio Profile
    }
  };

  return (
    <div className='signup-selection'>
      <h1>Choose Your Path</h1>
      <p>
        Is there a difference? <Link to="https://newgame.me/#faq">Check the FAQ</Link>
      </p>
      <div className="button-container">
        <button
          onClick={() => handleButtonClick('creator')} 
          className={selectedOption === 'creator' ? 'selected' : ''}
        >
          CREATOR
        </button>
        <button
          onClick={() => handleButtonClick('studio')} 
          className={selectedOption === 'studio' ? 'selected' : ''}
        >
          STUDIO
        </button>
      </div>
    <button 
      className="continue-path-button" 
      onClick={handleContinue} 
      disabled={!selectedOption}
      >
      <div className="text-wrapper">CONTINUE</div>
      <img src={continueIcon} alt="Continue" className="icon" /> {/* Use the SVG icon */}
    </button>
  </div>
  );
};

export default SignUpSelection;
