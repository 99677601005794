import { useLocation, Link } from 'react-router-dom'; // Import useLocation and Link
import logo from '../assets/images/logo_title.png'; // Import the logo image
import './Header.css'; // Ensure this line is present

const Header = () => {
  const location = useLocation(); // Get the current location

  // Check if the current path is one of the typeform routes
  return (
    <div className="header">
      <div className="banner" style={{ justifyContent: 'center' }}>
        <a href="https://app.newgame.me" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Logo" className="header-logo" />
        </a>
      </div>
      <div className="navigation">
        <a href="https://newgame.me/agency" className="nav-link">Agency</a>
        <a href="https://newgame.me/docs" target="_blank" rel="noopener noreferrer" className="nav-link">Docs</a>
      </div>
      <div className="header-right">
        {location.pathname === '/login' ? ( // Check if the current path is '/login'
          <Link to="/" className="nav-button">Sign Up</Link> // Link to Sign Up
        ) : (
          <a href="./login" className="nav-button">Sign In</a>
        )}
      </div>
    </div>
  );
};

export default Header;
