import React, { useEffect, useState, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { auth, database } from './firebase'; // Adjust the path as necessary
import HeaderApp from './components/HeaderApp'; // Import the HeaderApp component
import SignUpSelection from './components/SignUpSelection';
import CreatorProfile from './components/CreatorProfile';
import StudioProfile from './components/StudioProfile';
import Header from './components/Header'; // Import the standard header
import CreatorTypeform from './components/CreatorTypeform';
import StudioTypeform from './components/StudioTypeform'; // Import the new component
import Login from './components/Login';
import CreatorPage from './components/CreatorPage'; // Import the new component
import StudioPage from './components/StudioPage'; // Import your StudioPage component
import HeaderMobile from './components/HeaderMobile'; // Import the mobile Header component
import Footer from './components/Footer'; // Import the Footer component
import { onAuthStateChanged } from "firebase/auth";
import { ref, get } from "firebase/database";
import PasswordResetForm from './components/PasswordReset';
import PasswordSet from './components/PasswordSet';
import EditCreatorPage from './components/EditCreatorPage';
import PublicProfile from './components/PublicProfile';
// import LockScreen from './components/LockScreen'; // Comment out the LockScreen import

const App = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { userId } = useParams(); // Extract userId from URL parameters
	const [userProfilePic, setUserProfilePic] = useState(null); // State for user's profile picture
	const [username, setUsername] = useState(null); // State for user's display name
	const [name, setName] = useState(null); // State for user's name
	const [userData, setUserData] = useState(null); // State for user data
	const [isNavOpen, setNavOpen] = useState(false); // State for side nav visibility
	const isTypeformRoute = location.pathname === '/creator-typeform' || location.pathname === '/studio-typeform';
	const isCreatorPage = location.pathname.startsWith('/creator-page/');
	const isStudioPage = location.pathname.startsWith('/studio-page/');
	const isEditCreatorPage = location.pathname.startsWith('/edit-creator-page/');
	const isPublicProfile = /^[a-zA-Z0-9_-]+$/.test(location.pathname.split('/')[1]); // Check if the second segment is a valid username
	const isHeaderAppRoute = isCreatorPage || isStudioPage || isEditCreatorPage; // Include public profile in the header app route check
	const isMobile = window.innerWidth < 850; // Check if the screen width is less than 810px
	// const [isLocked, setIsLocked] = useState(false); // Remove this line
	const isSigningUp = location.pathname === '/signup-creator' || location.pathname === '/signup-studio'; // Check if the pathname is the signup selection page

	const hasNavigatedToCreatorPage = useRef(false); // Ref to track navigation

	useEffect(() => {
		const handleScrollToTop = () => {
			if (window.innerWidth < 850) {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			}
		};

		handleScrollToTop(); // Call the function on location change

	}, [location.pathname]);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			console.log('Auth state changed:', user); // Log the user object

			if (user) {
				// Check if the app is embedded in an iframe
				if (window.self === window.top) {
					const userRef = ref(database, 'users/' + user.uid);
					const snapshot = await get(userRef);
					const userData = snapshot.val();
					console.log('User data fetched:', userData); // Log the fetched user data

					if (userData) {
						setUserProfilePic(userData.profilePic); // Set the user's profile picture
						setUsername(userData.username); // Set the user's display name
						setName(userData.name); // Set the user's name
						setUserData(userData); // Set the user data

						// Only navigate if not on a header app route and not already navigated
						if (!isHeaderAppRoute && !hasNavigatedToCreatorPage.current) {
							if (userData.signedUp) {
								console.log('Navigating to creator page:', user.uid); // Log navigation
								navigate(`/creator-page/${user.uid}`); // Redirect to creator page
								hasNavigatedToCreatorPage.current = true; // Set to true after navigation
							} else {
								if (userData.userType === 'creator') {
									console.log('Navigating to creator typeform:', user.uid); // Log navigation
									navigate('/creator-typeform', { state: { userId: user.uid } });
									hasNavigatedToCreatorPage.current = true; // Set to true after navigation
								} else if (userData.userType === 'studio') {
									console.log('Navigating to studio typeform:', user.uid); // Log navigation
									navigate('/studio-typeform', { state: { userId: user.uid } });
									hasNavigatedToCreatorPage.current = true; // Set to true after navigation
								}
							}
						} else {
							console.log('User is on a header app route or has already navigated to creator page, no navigation performed.');
						}
					}
				}
			} else {
				console.log('User not authenticated, no automatic navigation'); // Log no navigation
				// Do not navigate to login automatically
			}

		}); // Closing the onAuthStateChanged function

		return () => unsubscribe();
	}, [navigate, isHeaderAppRoute]); // Include isHeaderAppRoute in the dependency array

	// Ensure the following useEffect is properly defined
	useEffect(() => {
		// Close the side nav when the route changes
		setNavOpen(false);
	}, [location.pathname]); // Listen for changes in the pathname

	useEffect(() => {
		const fetchUserData = async () => {
			const userRef = ref(database, 'users/' + userId);
			const snapshot = await get(userRef);
			const userData = snapshot.val();
			if (userData) {
				setUserData(userData);
				setUsername(userData.username);
				setName(userData.name); // Set the user's name
			}
		};

		fetchUserData();
	}, [userId]);

	// Remove the handleUnlock function
	// const handleUnlock = () => {
	// 	setIsLocked(false); // Unlock the app
	// };

	const showFooter = !isTypeformRoute && !isEditCreatorPage && !isPublicProfile;

	console.log('Footer visibility:', {
		isTypeformRoute,
		isEditCreatorPage,
		isPublicProfile,
		isSigningUp,
		shouldShowFooter: showFooter
	});

	return (
		<div className={`app ${isHeaderAppRoute ? 'header-app-route' : ''}`}>
			{/* {isLocked && !isPublicProfile && <LockScreen onUnlock={handleUnlock} />} */}
			{isHeaderAppRoute && (
				<HeaderApp 
					profilePic={userProfilePic} // Pass the profile picture here
					username={username} 
					name={name} // Pass the name prop here
					isNavOpen={isNavOpen} 
					setNavOpen={setNavOpen} 
					userId={userId} 
					userData={userData} // Pass userData if needed
				/>
			)}
			{!isTypeformRoute && !isCreatorPage && !isStudioPage && !isEditCreatorPage && !isPublicProfile && (isMobile ? <HeaderMobile /> : <Header />)}
			<div className={`main-content ${isNavOpen ? 'blur' : ''}`}>
				<Routes>
					<Route path="/" element={<SignUpSelection />} />
					<Route path="/signup-creator" element={<CreatorProfile />} />
					<Route path="/signup-studio" element={<StudioProfile />} />
					<Route path="/creator-typeform" element={<CreatorTypeform />} />
					<Route path="/studio-typeform" element={<StudioTypeform />} />
					<Route path="/login" element={<Login />} />
					<Route path="/studio-page/:userId" element={<StudioPage />} />
					<Route path="/creator-page/:userId" element={<CreatorPage />} />
					<Route path="/password-reset" element={<PasswordResetForm />} />
					<Route path="/password-set" element={<PasswordSet />} />
					<Route path="/edit-creator-page/:userId" element={<EditCreatorPage />} />
					<Route path="/:username" element={<PublicProfile />} />
				</Routes>
			</div>
			{showFooter && <Footer />}
		</div>
	);
};

export default App;
