import React, { useEffect, useState } from 'react'; // Import useState and useEffect
import { useParams, useNavigate } from 'react-router-dom';
import { ref, onValue } from "firebase/database"; // Import Firebase functions
import { database} from '../firebase'; // Import your Firebase database
import './CreatorPage.css'; // Import the CSS file
import locationDropIcon from '../assets/images/location-drop-icon.svg';
import axios from 'axios'; // Import Axios  

const regionNames = {
  NA: "North America",
  EU: "Europe",
  EAS: "East Asia",
  SA: "South America",
  AU: "Australia and Pacific Islands",
  CAS: "Central Asia",
  ME: "Middle East",
  NAF: "North Africa",
  RU: "Russia",
  SAF: "Sub-Saharan Africa",
  SAS: "South Asia",
  SEA: "South-East Asia",
  CA: "Central America",
};


const API_KEY = 'd56fb269b2d345f3a6261a0dc8a34a28'; // Your Embedly API key

const fetchEmbedData = async (url) => {
  if (!url) {
    console.error('No URL provided for embed data fetching');
    return null; // Return null if the URL is empty
  }
  
  try {
    const response = await axios.get(`https://api.embedly.com/1/oembed?key=${API_KEY}&url=${encodeURIComponent(url)}&native=true`);
    return response.data;
  } catch (error) {
    console.error('Error fetching embed data:', error);
    return null;
  }
};

const getPageTitle = (url) => {
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then(html => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      return doc.title; // Return the title of the page
    })
    .catch(error => {
      console.error('Error fetching page title:', error);
      return 'Error fetching title'; // Return an error message
    });
};

const CreatorPage = () => {
  const { userId } = useParams(); // Get userId from URL parameters
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [embedData, setEmbedData] = useState([]); // State for embed data
  const [studioResources, setStudioResources] = useState([]); // State for studio resources
  const [pageTitles, setPageTitles] = useState([]); // State for page titles
  const navigate = useNavigate();

  useEffect(() => {
    if (window.self !== window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);

  useEffect(() => {
    const userRef = ref(database, 'users/' + userId);
    const unsubscribe = onValue(userRef, async (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserData(data);
        // Fetch embed data for featured content
        if (data.featureContent) {
          const links = data.featureContent.split(',').map(link => link.trim()).filter(link => link); // Trim and filter out empty links
          const embedPromises = links.map(link => {
            console.log('Fetching embed data for URL:', link); // Log the URL
            return fetchEmbedData(link);
          });
          const embeds = await Promise.all(embedPromises);
          setEmbedData(embeds.filter(embed => embed)); // Filter out null responses
        }
        // Fetch studio resources
        if (data.studioResources) {
          const resources = data.studioResources.split(',').map(link => link.trim()).filter(link => link);
          setStudioResources(resources.map(url => ({ url }))); // Assuming you want to store URLs in an object
        }
      } else {
        console.error('No data found for user:', userId);
      }
      setLoading(false); // Set loading to false after data is fetched
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [userId]);

  useEffect(() => {
    const fetchTitles = async () => {
      const titles = await Promise.all(studioResources.map(resource => getPageTitle(resource.url)));
      setPageTitles(titles);
    };

    if (studioResources.length > 0) {
      fetchTitles();
    }
  }, [studioResources]);

  useEffect(() => {
    const handleResize = (e) => {
      let data;
      try {
        data = JSON.parse(e.data);
      } catch (error) {
        return;
      }

      if (data.context !== 'iframe.resize') {
        return;
      }

      const iframe = document.querySelector(`iframe[src="${data.src}"]`);
      if (iframe && data.height) {
        iframe.height = data.height;
      }
    };

    window.addEventListener('message', handleResize);

    return () => {
      window.removeEventListener('message', handleResize);
    };
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <h2>Building your profile...</h2>
        <div className="loading-spinner"></div>
      </div>
    ); // Show loading state while fetching data
  }

  if (!userData) {
    return <p>User data not found.</p>; // Handle case where userData is not available
  }
  
  return (
    <div className="creator-page header-app-route">
      <div className="profile-banner">
        <img src={userData.studioProfileBanner} alt="Profile Banner" className="banner-image" />
        <div className="overlay"></div>
        <div className="edit-button">
          <button className="nav-button" onClick={() => navigate(`/edit-studio-page/${userId}`)}>Edit Profile</button>
        </div>
        <div className="share-button">
          <button className="nav-button" onClick={() => navigate(`/${userData.username}`)}>Share</button>
        </div>
        <div className="profile-info">
          <img src={userData.profilePic} alt="Profile" className="profile-pic" />
          <h2 className="profile-displayname">{userData.studioName}</h2>
          <div className="profile-location">
            <img src={locationDropIcon} alt="Location Icon" className="location-drop-icon" />
            <p>{regionNames[userData.location] || userData.location}</p>
          </div>
        </div>
      </div>
      <div className="profile-details-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div className="question-container bio">
            <p>{userData.studioBio}</p>
          </div>
        <div className="profile-details">
          <div className="column center-column">
          <div className="question-container featured-content">
              <div className="section-title" style={{width: '80%', marginBottom: '20px'}}>
                <h3>Official Links</h3>
              </div>
              <div className="social-accounts-list" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                {studioResources.length > 0 ? (
                  studioResources.map((resource, index) => (
                    <div className="OFL-container" key={index}>
                      <p>{pageTitles[index] || 'Loading...'}</p> {/* Display the page title or loading message */}
                      <div className="embedded-content official-links" style={{ overflow: 'hidden' }}>
                        <iframe
                          src={resource.url}
                          title={`Resource ${index + 1}`}
                          width="100%"
                          height="400px" // Adjust height as needed
                          style={{ border: 'none' }} // Remove default border
                          allowFullScreen
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Studio Resource Links available.</p>
                )}
              </div>
            </div>
            <div className="question-container featured-content">
              <div className="section-title featured-content" style={{width: '80%'}}>
                <h3>Game Titles</h3>
              </div>
              {embedData.map((embed, index) => (
                <div key={index} className="embedded-content">
                  <div dangerouslySetInnerHTML={{ __html: embed.html }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatorPage;